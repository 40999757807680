import React from "react";
import Navbar from "../Navbar/Navbar";
import './SeparateProduct.css';
import NailerXSimg from "../../images/nailerXS.webp";
import { HiMail } from "react-icons/hi";
import { ImPhone } from "react-icons/im";
import checkMark from "../../images/check-mark.png";
import { RiQuestionFill } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";


export default function NailerXS() {

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="product-container">
          <h1>Reserve your AdMachTec<br /> Sheathing Bridge</h1>
          <p>Looking to revolutionize your wall panel manufacturing process? Look no further than AdMachTec's Sheathing Bridge. Our cutting-edge equipment is designed to maximize accuracy and efficiency, allowing you to produce high-quality sheathed wall panels time after time.</p>
          <br />
          <p>With the ability to configure nail placement for wall heights up to 16 feet and lengths up to 50 feet, our Sheathing Bridge is incredibly versatile. Seamless integration with common CAD and production software will have you up and running in no time. With our AI-powered defect detection and wall boundary sensing, you can be sure that your panels are consistently of the highest quality. Add the optional router unit for cutting out sheathed openings and you'll be able to take your wall panel manufacturing process to the next level. You won't be disappointed with the AdMachTec Sheathing Bridge.</p>
        </div>
        <div className="separate-product-container">
          <div className="separate-product-container-image">
            <img src={NailerXSimg} alt="sheathing bridge" />
          </div>
          <div className="separate-product-container-content">
            <h3>Standard Features</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill"/>  Rugged, precise nailing heads with Mega Coil support</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Auto Tilting at sheathing seams</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Nail depth control system for consistent high quality panels <sup>(2)</sup></p>
              <p><GoDotFill size={10} className="dot-fill"/> Low profile tracks to accommodate tight spaces</p>
              <p><GoDotFill size={10} className="dot-fill"/> 12' high x 20' long working area</p>
              <p><GoDotFill size={10} className="dot-fill"/> Precise Nailing Mode places the nails exactly where programmed</p>
              <p><GoDotFill size={10} className="dot-fill"/> Cloud Reports & Alerts - stay up-to-date with your production status!</p>
            </div>
            <h3>Options</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill"/> Up to 8 nailing heads - nail an 8' panel in under one minute!</p>
              <p><GoDotFill size={10} className="dot-fill"/> Integrated Router System - cutout sheathed openings and trim overhangs!</p>
              <p><GoDotFill size={10} className="dot-fill"/> Up to 16' high and 50' long working area</p>
              <p><GoDotFill size={10} className="dot-fill"/> Speed Boost - 20% increase to the speed of your nailing heads!</p>
              <p><GoDotFill size={10} className="dot-fill"/> Cassette reloading system for nails speeds the reloading process <sup>(2)</sup></p>
              <p><GoDotFill size={10} className="dot-fill"/> Hot feed system for Mega Coils allows operator to change coils when convenient <sup>(2)</sup></p>
              <p><GoDotFill size={10} className="dot-fill"/> Printer for marking panels</p>
            </div>
            <h4>Starting price: <span>$149000 <sup>(1)</sup> CAD</span></h4>
            <br/>
            <p><sup>(1)</sup> Price depends on configuration</p>
            <p><sup>(2)</sup> Features are in Patent process</p>

            <div className="separate-product-container-btns">
              <a href='/contact'><button><HiMail size={22} /> Contact Us</button></a>
              <a href='tel:+18003180389'><button><ImPhone size={20} /> Call Us</button></a>
            </div>

          </div>
        </div>

        <div className="sheathing-bridge-features">
          <table className="comparison-table">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Foundation</th>
                <th>Advanced</th>
                <th>Ultimate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mega Coil support
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-item">Supports up to 1200 nail coils, minimizing the need for frequent reloading and ensuring uninterrupted workflow</p>
                  </div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Auto-Tilt
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Nail guns are designed to tilt up to 12° at sheathing seams, ensuring secure nailing of sheathing edges with precision and reliability</p>
                  </div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Cloud based reports
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Delivers comprehensive data and statistics on real-time production output and performance</p>
                  </div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Precise Nailing Mode
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">For utmost precision in nail positioning</p>
                  </div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Automatic Nailing Around Openings
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Customizable nailing patterns around openings empower users with flexibility and precision, ensuring the creation of panels of the highest quality</p>
                  </div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Designer Configurable Nail Patterns
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Empowers panel designers to effortlessly configure the nailing pattern during the design phase, alleviating this responsibility from the operator and streamlining the process</p>
                  </div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Continuous Nailing Mode
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Accelerated nailing for increased panel throughput</p>
                  </div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Advanced Nail Path
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Streamlined nail path for expedited processing</p>
                  </div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Tag Authorization
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Implementation of RFID tags for operator authorization, streamlining login procedures and ensuring controlled access with efficiency</p>
                  </div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Automatic Wall Thickness Detection
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Eliminating the need for operators to set wall thickness reduces the likelihood of errors, thereby ensuring consistently high-quality output</p>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Automatic Nail Depth Control
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Attain uniform nail depth even amidst varying stud height or placement, minimizing rework and elevating overall quality standards</p>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Cassette System
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">The integrated nail feeding system reduces the time needed to replace an empty mega coil with a new one, optimizing efficiency in the process</p>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Continuous feed
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Integration with an AMT squaring table ensures that the next panel is queued, leading to faster cycle times</p>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>MODBUS
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Offers seamless integration with factory monitoring equipment</p>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              {/* <tr>
                <td>Auto Depth Control
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">
                    <p className="dropdown-iteem">Auto Depth Control content will be here</p>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr> */}
            </tbody>
          </table>
        </div>
        
        <div className="build-your-bridge">
          <p>For personalized configurations, please reach out to our representatives.</p>
          <a href="/contact"><button>Contact</button></a>
          {/* <a href="/products/build-sheathing-bridge"><button>Build Your Own</button></a> */}
        </div>
      </div>
    </>
  )
}