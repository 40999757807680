import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Gauge from "./Gauge";
import nailer from "../../images/buildYourOwnNailer.webp"
import './BuildYourOwn.css';
import SendBuildYourOwnForm from "./SendBuildYourOwnForm";

const nailerFeatures = [
  {
    id: 1,
    title: 'Continuous Nailing Mode',
    description: 'Continuous Nailing Mode: Faster nailing',
  },
  {
    id: 2,
    title: 'Advanced Nail Path',
    description: 'Advanced Nail Path: Optimized nail path for faster processing times',
  },
  {
    id: 3,
    title: 'Tag Authorization',
    description: 'Tag Authorization: Operator authorization with RFID tags to reduce operator input',
  },
  {
    id: 4,
    title: 'Automatic Wall Thickness Detection',
    description: 'Automatic Wall Thickness Detection: Operator does not need to set wall thickness',
  },
  {
    id: 5,
    title: 'Automatic Nail Depth Control',
    description: 'Automatic Nail Depth Control: Achieve consistent nail depth even with inconsistent stud width or placement',
  },
  {
    id: 6,
    title: 'Cassette System',
    description: 'Cassette System: Faster nail reloading',
  },
  {
    id: 7,
    title: 'Continuous feed',
    description: 'Continuous feed: With AMT squaring table, the next panel is queued for faster cycle times',
  },
  {
    id: 8,
    title: 'MODBUS',
    description: 'MOBUS: Provides integration with factory monitoring equipment'
  },
]

export default function BuildYourOwnNailer() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [performanceBoost, setPerformanceBoost] = useState(0);
  const [selectedDescription, setSelectedDescription] = useState([]);
  const [selectedNailerHead, setSelectedNailerHead] = useState(1);

  const product = "Nailer Basic";

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
    console.log('Features', selectedFeatures);
  }

  const handleAddFeature = (item) => {
    const isSelected = selectedFeatures.some((feature) => feature.id === item.id);
    let additionalBoost = 0;

    if (!isSelected) {
      setSelectedFeatures([...selectedFeatures, item]);
      setSelectedDescription([...selectedDescription, item.description]);
      additionalBoost = 12.5; // Add boost when adding a feature
    } else {
      setSelectedFeatures(prevFeatures =>
        prevFeatures.filter((feature) => feature.id !== item.id)
      );
      setSelectedDescription((prevDescriptions) =>
        prevDescriptions.filter((description) => description !== item.description));
      additionalBoost = -12.5; // Remove boost when removing a feature
    }

    // Adjust performanceBoost based on selectedFeatures
    setPerformanceBoost(prevBoost => prevBoost + additionalBoost);

    // Ensure inputValue is within range
    const inputValue = selectedNailerHead < 1 || selectedNailerHead > 8 ? 1 : selectedNailerHead;
    setSelectedNailerHead(inputValue);
  };


  const handleAddNailerHead = (event) => {
    const inputValue = parseInt(event.target.value);
    let additionalBoost = 0;

    // Calculate boost from selected features
    const featuresBoost = selectedFeatures.length * 12.5;

    // Adjust performance boost based on selected nailer heads
    switch (inputValue) {
      case 2:
        additionalBoost = 90;
        break;
      case 3:
        additionalBoost = 171;
        break;
      case 4:
        additionalBoost = 244;
        break;
      case 5:
        additionalBoost = 310;
        break;
      case 6:
        additionalBoost = 369;
        break;
      case 7:
        additionalBoost = 422;
        break;
      case 8:
        additionalBoost = 470;
        break;
      default:
        additionalBoost = 0;
        break;
    }

    // Set the performanceBoost state
    setPerformanceBoost(featuresBoost + additionalBoost);

    // Update selectedNailerHead state
    setSelectedNailerHead(inputValue);
  };


  return (
    <>
      <Navbar />
      {showContactForm && <SendBuildYourOwnForm features={selectedFeatures} productName={product} isShown={showContactForm.toString()} toggleContactForm={toggleContactForm} amountOfHeads={selectedNailerHead} />}
      <div className="container-wrapper static-background">
        <div className="build-your-own-wrapper">
          <div className="build-your-own-top-container">
            <div className="build-your-own-left-container">
              <div className="build-your-own-left-container-title">Custom Sheathing Bridge</div>
              {/* <div className="build-your-own-left-container-numbers-block">
                <div className="build-your-own-left-container-numbers">
                  {nailerHeads.map((item, index) => (
                  <p 
                  onClick={() => handleAddNailerHead(item)}
                  key={index}
                  className={selectedNailerHead === item ? "selected" : ""}
                  >
                      {item}
                    </p>
                ))}
                </div>
                <p>Amount of Nailer Heads: {selectedNailerHead}</p>
              </div> */}
              <div className="build-your-own-left-container-add-feature">
                <div className="build-your-own-left-container-add-feature-block">
                  <p>Number of Nailing Heads</p>
                  <input
                    type="number"
                    value={selectedNailerHead}
                    min={1}
                    max={8}
                    onChange={handleAddNailerHead} />
                </div>
                {
                  nailerFeatures.map((item, index) => (
                    <div key={index} className="build-your-own-left-container-add-feature-block">
                      <p>{item.title}</p>
                      <button
                        onClick={() => handleAddFeature(item)}
                        style={{ backgroundColor: selectedFeatures.some((feature) => feature.id === item.id) ? "#fafafa" : "transparent", color: selectedFeatures.some((feature) => feature.id === item.id) ? "#000000" : "#fafafa" }}
                      >
                        {selectedFeatures.some((feature) => feature.id === item.id) ? "Delete" : "Add"}
                      </button>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="build-your-own-right-container">
              <div className="build-your-own-right-container-perfomance">
                <div className="build-your-own-right-container-perfomance-img">
                  {/* <img src={Boost} alt="Boost" /> */}
                  <Gauge value={performanceBoost / 100} />
                </div>
                <div className="build-your-own-right-container-perfomance-count">
                  <p>Performance boost <sup>(1)</sup></p>
                  <p>{performanceBoost}%</p>
                  <button onClick={() => toggleContactForm(selectedFeatures)}>Send Inquiry</button>
                </div>
              </div>
              <div className="build-your-own-right-container-description">
                <p>SELECTED FEATURES:</p>
                <p>Number of Nailing Heads: {selectedNailerHead}</p>
                {selectedDescription.map((description, index) => (
                  <p key={index}>{description}</p>
                ))}
              </div>
            </div>
          </div>

          <div className="build-your-own-bottom-container">
            <p className="build-your-own-bottom-container-description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit.</p>
            <div className="build-your-own-bottom-container-img"><img src={nailer} alt="Nailer" /></div>
          </div>
          <p><sup>(1)</sup> Performance boost is estimated. Actual result will vary depending on a number of run-time factors.</p>
        </div>
      </div>
    </>
  )
}