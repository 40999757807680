import React from 'react'
import HeroSection from '../components/HeroSection';
import Navbar from '../components/Navbar/Navbar';
import MainPageProducts from '../components/MainPageProducts/MainPageProducts';
import EngineeredForYou from '../components/EngineeredForYou';
import AffordableLineIsHere from '../components/AffordableLineIsHere/AffordableLineIsHere';
import OurSoftware from '../components/OurSoftware/OurSoftware';
import SubscribeForNews from '../components/SubscribeForNews/SubscribeForNews';

const Home = () => {

  return (
    <div className='static-background'>
      <Navbar />
      <HeroSection />
      <MainPageProducts />
      <EngineeredForYou />
      <AffordableLineIsHere />
      <OurSoftware />
      <SubscribeForNews />
    </div>
  )
}

export default Home;