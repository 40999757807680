import React from "react";
import Navbar from "./Navbar/Navbar";
import './About.css';
import CompanyLocation from "./CompanyLocation/CompanyLocation";

export default function About() {

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="about-container">
          <h1>Our Story</h1>
          <p>Founded in 2020, AdMachTec Inc. is a Canadian corporation headquartered in Winnipeg, Manitoba. Our journey began with the development and manufacturing of a custom automated sheathing station. Since then, we've evolved to pioneer a comprehensive line of equipment for the automated manufacturing of prefabricated wood wall panels.
          </p> <br />
          <p>Backed by a management team boasting over 50 years of collective expertise in software development, electronics design, mechanical engineering, systems integration, prototype development, and business management, we're uniquely positioned to craft innovative solutions that revolutionize our clients' production processes.</p> <br />
          <p>Though we're relatively new to the prefab equipment industry, our nimbleness enables us to attentively listen to our clients' needs and swiftly deliver tailored solutions. Committed to exceptional customer service and support, we pride ourselves on delivering high-quality products that precisely meet our customers' requirements within tight time frames.</p>
          <br/>
          <h3>Mission</h3>
          <p>To provide state-of-the-art, highly efficient, and precise equipment customized for automated manufacturing and production processes, while fostering partnerships with our clients to enhance productivity, innovation, and a steadfast commitment to excellence.</p>
          <br />
          <h3>Vision</h3>
          <p>To lead the widespread integration of robotic technology throughout industrial and agricultural production, fostering efficiency, sustainability, and progressive advancement.</p>
          <br />
          <h3>Values</h3>
          <p>At our core, we prioritize delivering exceptional value with unwavering honesty and integrity. Grounded in our commitment to innovative robotics solutions, we focus on driving process optimization, enhancing productivity, and fostering success for our valued clients and partners worldwide.</p>
        </div>
        <div className="location-container">
          <h1>Location</h1>
          <CompanyLocation />
        </div>
      </div>
    </>
  )
}