import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./Support.css";
import { QuestionsAndAnswers } from "../QuestionsAndAnswers";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";


export default function Support() {
  const [activeCategory, setActiveCategory] = useState("sheathingBridge");
  const [activeIndex, setActiveIndex] = useState(null);

  const toggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const activeQuestions = QuestionsAndAnswers[activeCategory];


  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="support-container">
          <h1>Product Support</h1>
          <p>Select a Product Inquiry, Sales, or a Technical Support:</p>
          <div className="support-questions-container">
            <div className="support-questions-left-container">
              <div className="support-questions-categories">
                {Object.keys(QuestionsAndAnswers).map((category) => (
                  <p key={category} onClick={() => handleCategoryClick(category)}>
                    {category.replace(/([A-Z])/g, ' $1').trim()} FAQ
                    {activeCategory === category && <MdOutlineKeyboardArrowRight size={22} />}
                  </p>
                ))}
              </div>
              <div className="support-questions-content">
                <p>Over time, we've encountered common questions from our clients, and we've made sure to address them. However, if you have any additional queries or need further information, please feel free to contact us via phone or through our contact form. Our service experts will promptly assist you with any assistance you require.</p>
              </div>
            </div>

            <div className="support-answers">
              <ul className="accordion">
                {activeQuestions.map((faq, index) => (
                  <li key={index} className={`accordion_item ${activeIndex === index ? "active" : ""}`}>
                    <button className="button" onClick={() => toggle(index)}>
                      {faq.question}
                      <span className="control">{activeIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
                    </button>
                    <div className={`answer_wrapper ${activeIndex === index ? "open" : ""}`}>
                      <div className="answer">{faq.answer}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}