import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MainPageProducts.css";
import { ProductsList } from "../ProductsList";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";


export default function MainPageProducts() {

  const [startIndex, setStartIndex] = useState(0);
  const visibleProducts = ProductsList.slice(startIndex, startIndex + 3);

  const handleNext = () => {
    const nextIndex = startIndex + 1;
    if (nextIndex <= ProductsList.length - 3) {
      setStartIndex(nextIndex);
    }
  };

  const handlePrev = () => {
    const prevIndex = startIndex - 1;
    if (prevIndex >= 0) {
      setStartIndex(prevIndex);
    }
  };

  const isScrollAvailable = ProductsList.length > 3;

  return (
    <div className="main-products-container">
      {visibleProducts.map((product, index) => (
        <Link to={product.path} key={product.id} className="main-products-container-card-link">
          <div key={product.id} className="main-products-container-card">
            <img src={product.img} alt={`Slide ${index}`} />
            <div className="container-card-description">
              <h2>{product.title}</h2>
              <p>{product.bullet1}</p>
              <p>{product.bullet2}</p>
              <p>{product.bullet3}</p>
            </div>
          </div>
        </Link>
      ))}
      <div className="main-products-container-btns">
        <button aria-label="previous" onClick={handlePrev} className={`main-products-left-btn {!isScrollAvailable || startIndex === 0 ? "disabled-btn" : ""}`}><IoIosArrowDropleftCircle size={40} color={!isScrollAvailable || startIndex === 0 ? "grey" : "#FAFAFA"} /></button>
        <button aria-label="next" onClick={handleNext} className={`main-products-right-btn {!isScrollAvailable || startIndex === ProductsList.length - 3 ? "disabled-btn" : ""}`}><IoIosArrowDroprightCircle size={40} color={!isScrollAvailable || startIndex === ProductsList.length - 3 ? "grey" : "#FAFAFA"} /></button>
      </div>
    </div>
  )
}