import React, { useState } from 'react';
import './NavbarMenu.css';
import LOGO from '../../images/White-logo.png'
import { IoClose } from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa";
import ContactForm from '../ContactForm/ContactForm';

const NavbarMenu = ({ ProductsList, closeMenu }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  }

  return (
    <>
      {showContactForm && <ContactForm isshown={showContactForm.toString()} toggleContactForm={toggleContactForm} />}
      <div className="navbar-menu">
        <div className='navbar-menu-logo'>
          <img src={LOGO} alt='Logo' />
          <button onClick={closeMenu}><IoClose color='white' size={25} /></button>
        </div>
        <div className='menu-products-list'
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          <a href="/products" className="menu-nav-item">Products <FaAngleRight size={20} /></a>
          {isDropdownOpen && (
            <div className="dropdown-content-menu">
              {ProductsList.map((product) => (
                <a key={product.id} href={product.path}>{product.title}</a>
              ))}
            </div>
          )}
        </div>
        <div className='menu-line'></div>
        <div><a href="/support" className="menu-nav-item">Support</a></div>
        <div className='menu-line'></div>
        <div><a href="/about" className="menu-nav-item">About</a></div>
        <div className='menu-line'></div>
        <div><a href="https://portal.admachtec.com" target='_blank' rel='noreferrer' className="menu-nav-item">Portal</a></div>
        <a href='/contact'>
          <button className='menu-contact-button'>Contact</button></a>
      </div>
    </>
  );
};

export default NavbarMenu;
