import sheathingBridge from '../images/Landing-carousel/main-sheathing-bridge.webp';
import extruder from '../images/Landing-carousel/main-extruder.webp';
import wallPanelLine from '../images/Landing-carousel/main-panel-line.webp';
import squaringTable from '../images/Landing-carousel/main-squaring-table.webp';
import ownProject from '../images/Landing-carousel/main-idea.png';

export const ProductsList = [
  {
    id: 'sheathingBridge',
    title: 'Sheathing Bridge',
    path: '/products/sheathing-bridge',
    className: 'dropdown-link',
    description: 'With the ability to configure nail placements for wall heights up to 16 feet and lengths up to 24 feet, our Sheathing Bridge is incredibly versatile. Plus, it integrates seamlessly with common CAD software and can even be customized to support other programs.',
    bullet1: 'Expansive working area of up to 16\' x 50\'',
    bullet2: 'Equipped with up to 8 nailing heads',
    bullet3: 'Streamlined software suite, compatible across all production platforms',
    support: 'FAQ',
    img: sheathingBridge
  },
  {
    id: 'squaringTable',
    title: 'Squaring Table',
    path: '/products/squaring-table',
    className: 'dropdown-link',
    description: 'The squaring table has been specifically engineered to maintain the squareness of wall panels during the attachment of sheathing and the cutting of rough openings, guaranteeing consistently high-quality panels.',
    bullet1: 'Up to 16\' x 50\' working area',
    bullet2: 'Power conveyors available for effortless panel handling',
    bullet3: 'Available upstream and downstream integration for automated production',
    support: 'Support text',
    img: squaringTable
  },
  {
    id: 'wallPanelLine',
    title: 'Wall Panel Line',
    path: '/products/wall-panel-line',
    className: 'dropdown-link',
    description: 'By integrating our range of wall panel products, we can assist you in tailoring and optimizing a production line that guarantees optimal returns on your investment in equipment, facilities, and labour.',
    bullet1: 'Create a high-efficiency wall panel line by combining our products',
    bullet2: 'Automate panel movement seamlessly through the line',
    bullet3: 'Enhance productivity with sub-component tables and roller conveyors',
    support: 'Support text',
    img: wallPanelLine
  },
  {
    id: 'extruder',
    title: 'Extruder',
    path: '/products/extruder',
    className: 'dropdown-link',
    description: 'The AdMachTec Extruder is currently undergoing development. Rest assured, this innovation will establish unprecedented benchmarks in the industry, delivering unparalleled speed and top-tier quality for your wall framing needs.',
    bullet1: 'Up to 16\' x 24\' working area',
    bullet2: 'Single person operation',
    bullet3: 'Mega Coil support',
    support: 'Support text',
    img: extruder

  },
  {
    id: 'idea',
    title: 'Do You have an idea?',
    path: '/contact',
    className: 'dropdown-link',
    description: 'Feel free to share your project concepts, and our experts will assist you in turning your ideas into reality as swiftly as possible.',
    img: ownProject
  },

]