import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Home from './pages';
import About from './components/About';
import Support from './components/Support/Support';
import Products from './components/Products';
import Footer from './components/Footer/Footer';
import Legal from './components/Legal/Legal';
import ContactPage from './components/ContactPage/ContactPage';
import NailerXS from './components/Products/NailerXS';
import NailerXsPRO from './components/Products/NailerXsPRO';
import WallPanelLine from './components/Products/WallPanelLine';
import SquaringTable from './components/Products/SquaringTable';
import Careers from './components/Careers/Careers';
import BuildYourOwnNailer from './components/BuildYourOwn/BuildYourOwnNailer';
import BuildYourOwnSquaringTable from './components/BuildYourOwn/BuildYourOwnSquaringTable';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/support' element={<Support />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/products' element={<Products />} />
          <Route path='/legal' element={<Legal />} />
          <Route path='/careers' element={<Careers />} />
          {/* <Route path='/news' element={<News />} /> */}
          <Route path='/products/sheathing-bridge' element={<NailerXS />} />
          <Route path='/products/extruder' element={<NailerXsPRO />} />
          <Route path='/products/wall-panel-line' element={<WallPanelLine />} />
          <Route path='/products/squaring-table' element={<SquaringTable />} />
          <Route path='/products/build-sheathing-bridge' element={<BuildYourOwnNailer />} />
          <Route path='/products/build-squaring-table' element={<BuildYourOwnSquaringTable />} />
          {/* <Route path='/software' element={<SoftwarePage />} /> */}
        </Routes>
        <Footer />
      </Wrapper>
    </BrowserRouter>
  );
}


export default App;
