import React, { useState, useEffect } from 'react';
import './Navbar.css';
import LOGO from '../../images/White-logo.png';
import { ProductsList } from '../ProductsList';
import { IoMenu } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import NavbarMenu from './NavbarMenu';

const Navbar = () => {
  const [isTop, setIsTop] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNavbarMenu, setShowNavbarMenu] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsTop(window.scrollY < 50);
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const toggleNavbarMenu = () => {
    setShowNavbarMenu(!showNavbarMenu);
  }

  return (
    <>
      <nav className={`navbar ${!isTop ? 'scrolled' : ''}`}>
        <a href="/" className="logo"><img src={LOGO} alt="Logo" /></a>
        <div className={`nav-links`}>
          <div className='products-list' onMouseEnter={() => setIsMenuOpen(true)}
            onMouseLeave={() => setIsMenuOpen(false)}>
            <a href="/products" className="nav-item">Products <IoIosArrowDown color='white' size={20} /></a>
            {isMenuOpen && (

              <div className="dropdown-content">
                {ProductsList.map((product) => (
                  <a key={product.id} href={product.path}>{product.title}</a>
                ))}
              </div>
            )}
          </div>
          <div><a href="/support" className="nav-item">Support</a></div>
          <div><a href="/about" className="nav-item">About</a></div>
        </div>
        <a href="https://portal.admachtec.com" target='_blank' rel='noreferrer' className="portal-link">Portal</a>
        <a className='contact-button' href='/contact'>Contact</a>

        <div className={`menu-button`} onClick={toggleNavbarMenu}><IoMenu color='white' size={35} /></div>

        {showNavbarMenu && (
          <NavbarMenu ProductsList={ProductsList} closeMenu={() => setShowNavbarMenu(false)} />
        )}
      </nav>
    </>
  );
};

export default Navbar;
