import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  0 30px;
  height: 908px;
  position: relative;
  margin-bottom: 50px;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0,0,0,0.2) 0%, 
      rgba(0,0,0,0.6) 100%), 
      linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
      z-index: 2;
  }
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const HeroButton = styled.div`
  background-color: var(--white);
  color: var(--black);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid var(--white);
  border-radius: var(--border-radius);
  padding: 10px 65px;
  font-size: 18px;
  transition: 0.5s all ease-in-out;

  :hover {
    text-decoration: none;
    background: transparent;
    color: var(--white);
    border: 1px solid var(--white);
    transition: 0.5s all ease-in-out;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    padding: 10px 50px;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 400px) {
    padding: 10px 25px;
  }
`

export const HeroBgImg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`
export const HeroH1 = styled.h1`
  margin: 0;
  color: #fff;
  font-size: 48px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media screen and (max-width: 780px) {
    font-size: 32px;
  }
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 32.5px;
  }
`

export const HeroP = styled.p`
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 780px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`

export const HeroBtnWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Link = styled(LinkR)`
  text-decoration: none;
`