import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { FaHome, FaClock, FaPhone } from 'react-icons/fa';
import './CompanyLocation.css';

const CustomMarker = ({ isActive, data, map }) => {
  const [refReady, setRefReady] = React.useState(false);
  let popupRef = React.useRef();

  React.useEffect(() => {
    if (refReady && isActive) {
      map.openPopup(popupRef);
    }
  }, [isActive, refReady, map]);

  return (
    <Marker position={data.position}>
      <Popup
        ref={(r) => {
          popupRef = r;
          setRefReady(true);
        }}
      >
        <b>AdMachTec Inc</b> <br />
        2-27 South Landing Drive, Winnipeg, MB R4G 0C4 <br />
        tel: +1 (204) 941-3909
      </Popup>
    </Marker>
  );
};

export default function CompanyLocation() {
  const [map, setMap] = React.useState(null);
  const position = [49.81, -97.25]
  return (
    <div className='location-map'>
      <MapContainer ref={setMap} center={position} zoom={4.5} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <CustomMarker
          isActive
          map={map}
          data={{ position: position }}
        />
      </MapContainer>
      <div className='description-container'>
        <h4>AdMachTec Inc</h4>
        <p> <FaHome /> 2-27 South Landing Drive, Winnipeg, MB R4G 0C4</p>
        <p><a href='tel:+12049413909'><FaPhone /> (204) 941-3909</a></p>
        <p> <FaClock /> Hours: 9AM - 5PM CST, M-F</p>
      </div>
    </div>
  )
}
