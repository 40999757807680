import React from "react";
import './AffordableLineIsHere.css';
import AffordableLine from '../../images/affordable-line.webp';
import { Link } from "react-router-dom";

export default function AffordableLineIsHere() {
  return (
    <div className="affordable-line">
      <div className="affordable-line-img">
        <img src={AffordableLine} alt="Manufacture" />
      </div>
      <div className="affordable-line-description">
        <h2>Introducing Our Affordable Wall Panel Line</h2>
        <p>Engineered with cutting-edge technology, our Wall Panel Line is meticulously crafted to elevate your production capabilities. By integrating this advanced system into your operations, you'll not only accelerate the manufacturing process but also enhance quality control, minimizing human errors. What's more, our user-friendly software ensures seamless operation, empowering your team with intuitive tools for maximum efficiency.
        </p>
        <Link className="affordable-line-btn" to='/products/wall-panel-line'>
          <div>Explore Our Wall Panel Line</div>
        </Link>
      </div>

    </div>
  )
}