export const QuestionsAndAnswers =
{
  sheathingBridge: [
    {
      id: 1,
      question: "How long does it take to get my equipment?",
      answer: "Delivery time from order will vary depending on the type of equipment ordered, but typical lead times are 18-25 weeks."
    },
    {
      id: 2,
      question: "Who installs the equipment?",
      answer: "Our installation team will install your new AMT equipment."
    },
  ],


  extruder: [
    {
      id: 1,
      question: "When will your extruder be released?",
      answer: "We expect the extruder to come to market in Q1 2025."
    },
    {
      id: 2,
      question: "Extruders seem to be much to slow for our production line. How fast will your extruder frame a wall?",
      answer: "Our extruder is faster than any extruder currently in the market."
    },
  ],
  wallPanelLine: [
    {
      id: 1,
      question: "What is the Wall Panel Line?",
      answer: "Our wall panel line consists of our extruder or framing table, squaring/sheathing table, and sheathing bridge, seamlessly integrated to offer both semi-automated and fully automated options for wall panel production."
    },
    {
      id: 2,
      question: "What are the dimensions for your Wall Panel Line?",
      answer: "The dimensions of our wall panel line are dependent on the configuration selected. Give us a call to obtain more information!"
    },
    {
      id: 3,
      question: "What is the cost of your Wall Panel line?",
      answer: "The cost of our wall panel line is dependent on your desired configuration. Give us a call to discuss!"
    },
  ],
  squaringTable: [
    {
      id: 1,
      question: "What height of walls does your squaring table support?",
      answer: "Our squaring table will support walls from 4' high to 16' high and up to 50' long, depending on your desired configuration."
    },
    {
      id: 2,
      question: "Will you squaring table support raked walls?",
      answer: "Our squaring table will support raked walls in a standard orientation up to 16' high. Taller raked walls can be supported when rotated."
    },
    {
      id: 3,
      question: "Is your squaring table compatible with other manufacturers nailing bridges?",
      answer: "Yes, our squaring table can be used with other manufacturers nailing bridges. It is designed with the industry standard height of 750mm."
    },
  ],
}