import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Navbar from "../Navbar/Navbar";
import { ImLocation, ImPhone, ImClock } from "react-icons/im";
import "./ContactPage.css";

export default function ContactPage() {
  const [message, setMessage] = useState();
  const [company, setCompany] = useState("")
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [errorMessageClass, setErrorMessageClass] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      company_name: company,
      client_name: clientName,
      email: email,
      phone_number: phoneNumber,
      client_message: clientMessage
    }
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((response) => {
        console.log('Contact Form sent successfully!', response);
        setCompany('');
        setClientName('');
        setEmail('');
        setPhoneNumber('');
        setClientMessage('');
        setMessage('Contact Form sent successfully!');
        setErrorMessageClass(false);
      })
      .catch((error) => {
        console.error('Error sending Contact Form: ', error);
        setMessage('Something went wrong! Please try again!');
        setErrorMessageClass(true);
      });
  };

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="contact-container-wrapper">
          <div className="information-container">
            <h1>Information</h1>
            <p>With a collective experience spanning over 50 years in industrial and agricultural product development, our team stands ready to address your needs. Contact us today to explore how our expertise can tailor solutions to meet your requirements. We're here to assist you every step of the way!</p>
            <div>
              <p>
                < ImLocation className="info-icon" />
                2 - 27 South Landing Drive, Winnipeg, MB R4G 0C4</p>
              <p>
                <a href='tel:+18003180389'>
                  <ImPhone className="info-icon" />
                  Toll Free: (800) 318-0389, </a>
                <a href='tel:+12049413909'> Local: (204) 941-3909</a>
              </p>
              <p>
                <ImClock className="info-icon" />
                Mon - Fri 9.00 - 17.00</p>
            </div>
          </div>
          <div className="contact-form-container">
            <h1>Contact Form</h1>
            <form onSubmit={sendEmail}>
              <input
                required
                type="text"
                value={company}
                name="company_name"
                placeholder="Company Name"
                onChange={(e) => setCompany(e.target.value)} />
              <input
                required
                type="text"
                value={clientName}
                name="client_name"
                placeholder="Your Name"
                onChange={(e) => setClientName(e.target.value)} />
              <input
                required
                type="email"
                value={email}
                name="email"
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)} />
              <input
                type="text"
                value={phoneNumber}
                name="phone_number"
                placeholder="Your Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)} />
              <textarea
                required
                type="text"
                value={clientMessage}
                name="client_message"
                placeholder="Your Message Here"
                onChange={(e) => setClientMessage(e.target.value)} />
              {message && <p className={`success-message ${errorMessageClass ? 'error-text' : ''}`}>{message}</p>}
              <button type="submit" className="contact-form-container--btn">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}