import React from "react";
import "./Banner.css";

export default function Banner(props) {
  return (
    <div className="banner-container">
      <img src={props.img} alt="banner"/>
      <h1>{props.title}</h1>
    </div>
  )
}