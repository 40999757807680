import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import './Products.css';
import { ProductsList } from "./ProductsList";
import ContactForm from "./ContactForm/ContactForm";
import Banner from "./Banner/Banner";
import ProductsImg from "../images/landing-main.webp";

export default function Products() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [product, setProduct] = useState('');

  const toggleContactForm = (productName) => {
    setShowContactForm(!showContactForm);
    setProduct(productName);
  }

  return (
    <>
      <Navbar />
      {showContactForm && <ContactForm productName={product} isshown={showContactForm.toString()} toggleContactForm={toggleContactForm} />}
      <Banner img={ProductsImg} title="Choose from our product line or inquire about custom project" />
      <div className="static-background">
        <div className="products-container-wrapper">
          <div className="card-container">
            {ProductsList.slice(0, -1).map((i, index) => {
              const cardClasses = `card-item ${index % 2 !== 0 ? "reverse" : ""}`;
              return (
                <div key={i.id} className={cardClasses}>
                  <div className="card-img">
                    <a href={i.path}><img src={i.img} alt="product" /></a>
                  </div>
                  <div className="card-description">
                    <a href={i.path}><h3>{i.title}</h3></a>
                    <a href={i.path}><p>{i.description}</p> </a>
                    
                    <div onClick={() => toggleContactForm(i.title)} className="card-item-btn">INQUIRE ABOUT THIS ITEM</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}