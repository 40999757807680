import React from "react";
import './EngineeredForYou.css';
import EngineeredForYouImg from '../images/engineered-for-you.webp';
import { Link } from "react-router-dom";

export default function EngineeredForYou() {
  return (
    <div className="engineered-for-you">
      <div className="engineered-for-you-description">
        <h2>Engineered For You</h2>
        <p>AdMachTec's wall panel robotics are perfectly suited for companies specializing in wood wall panels, manufactured home construction, and modular building. Crafted with our customers' needs at the forefront, our wall panel and floor cassette equipment is designed for seamless operation, significantly enhancing production efficiency.
        </p>
        <Link className="engineered-for-you-btn" to='/products'>
          <div>Explore our Products</div>
        </Link>
      </div>
      <div className="engineered-for-you-img">
        <img src={EngineeredForYouImg} alt="Manufacture" />
      </div>
    </div>
  )
}