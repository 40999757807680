import React from "react";
import Navbar from "../Navbar/Navbar";
import './SeparateProduct.css';
import extruder from "../../images/extruder-coming-soon.webp";
import { HiMail } from "react-icons/hi";
import { ImPhone } from "react-icons/im";
import { GoDotFill } from "react-icons/go";

export default function NailerXsPRO() {

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="product-container">
          <h1>Reserve your<br /> Extruder</h1>
          <p>The AdMachTec Extruder is currently in the developmental stages. Be assured, this innovation will set new standards in the industry, boasting unrivaled speed and top-tier quality. </p>
          <br />
          <p>For state-of-the-art automated wall framing solutions, your search ends with the AdMachTec Extruder.</p>
        </div>
        <div className="separate-product-container">
          <div className="separate-product-container-image">
            <img src={extruder} alt="Extruder" />
          </div>
          <div className="separate-product-container-content">
            <h3>Features</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill"/> Standard 12' Wall Height x 20' Panel Length</p>
              <p><GoDotFill size={10} className="dot-fill"/> Single person operation</p>
              <p><GoDotFill size={10} className="dot-fill"/> Enhanced safety and speed</p>
              <p><GoDotFill size={10} className="dot-fill"/> Integration with AMT’s Squaring/Sheathing Table</p>
            </div>
            <h3>Options</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill"/> Raked Wall Support</p>
              <p><GoDotFill size={10} className="dot-fill"/> Up to 16' x 24' working area</p>
              <p><GoDotFill size={10} className="dot-fill"/> Mega Coil support</p>
            </div>
            {/* <h4>Starting price: <span>$150,000.00<sup>1</sup> CAD</span> shipping included</h4>
            <p><sup>(1)</sup> Price depends on configuration</p> */}
            <div className="separate-product-container-btns">
              <a href='/contact'><button><HiMail className="info-icon" /> Contact Us</button></a>
              <a href='tel:+18003180389'><button><ImPhone className="info-icon" /> Call Us</button></a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}