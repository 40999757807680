import React from "react";
import Navbar from "../Navbar/Navbar";
import './SeparateProduct.css';
import Saw from "../../images/Line.webp"
import { HiMail } from "react-icons/hi";
import { ImPhone } from "react-icons/im";
import { GoDotFill } from "react-icons/go";

export default function WallPanelLine() {

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="product-container">
          <h1>Reserve your<br /> Wall Panel Line</h1>
          <p>Our production lines have been meticulously crafted to streamline the entire process of manufacturing wall panels and floor cassettes. Each production line is equipped with a carefully arranged set of products and accessories, ensuring a seamless and uninterrupted production flow. The configuration of a production line is tailored to the specific production objectives of an organization and the layout of their facility.</p>
          <br />
          <p>Whether you're just starting your manufacturing venture or have years of experience in the industry, AdMachTec offers a diverse range of production lines to cater to your unique requirements.</p>
          <br />
          {/* <p>The Wall Panel Line is ideal for customers that want to manually fasten wall panels by hand. This line is also compatible with those using plate-marking saws. Furthermore, AdMachTec is ready to collaborate with you to propose a customized layout that aligns perfectly with your organization's needs. </p> */}
        </div>

        <div className="separate-product-container">
          <div className="separate-product-container-image">
            <img src={Saw} alt="Saw" />
          </div>
          <div className="separate-product-container-content">
            <h3>Features</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill"/>  Wall Framing Station</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Sheathing Tacking Station</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Sheathing Nailing Station</p>
            </div>
            <h3>Options</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill"/>  Power Conveyors</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Sub Component Tables</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Swing away rollers</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Software Integration for coordinated movement of panels through the line</p>
              <p><GoDotFill size={10} className="dot-fill"/>  Software enhancements for load levelling</p>
            </div>
            <h4>Starting price: <span>$270000<sup>1</sup> CAD</span></h4>
            <br/>
            <p><sup>(1)</sup> Price depends on configuration</p>

            <div className="separate-product-container-btns">
              <a href='/contact'><button><HiMail className="info-icon" /> Contact Us</button></a>
              <a href='tel:+18003180389'><button><ImPhone className="info-icon" /> Call Us</button></a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}