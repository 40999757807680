import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';
import LOGO from '../../images/White-logo.png';
import instagram from '../../images/Social/instagram.png'
import facebook from '../../images/Social/facebook.png';
import twitter from '../../images/Social/twitter.png';
import linkedin from '../../images/Social/linkedin.png';
import youtube from '../../images/Social/youtube.png';

const Footer = () => {

  return (
    <div className='footer-container'>
      <div className='footer-section-one'>
        <div className='footer-section-one-links'>
          <Link className='footer-link' to='/careers'><p>Careers</p></Link>
          <Link className='footer-link' to='/about'><p>About</p></Link>
          {/* <Link className='footer-link' to='/safety'><p>Safety</p></Link> */}
          <Link className='footer-link' to='/support'><p>Support</p></Link>
          <Link className='footer-link' to='/legal'><p>Terms of Service</p></Link>
          <Link className='footer-link' to='/contact'><p>Contact</p></Link>
        </div>
      </div>
      <div className='footer-section-two'>

        <div className='footer-logo'>
          <Link className='footer-link' to='/'>
            <img src={LOGO} alt='Logo' /></Link>
        </div>
        <div>
          <small className='rights'>AdMachTec © 2024</small>
        </div>
        <div className='footer-social'>
          <a href={'https://www.instagram.com/admachtec/?igshid=YmMyMTA2M2Y%3D'} target="_blank" rel="noopener noreferrer"><img src={instagram} alt='instagram' /></a>
          <a href={'https://www.facebook.com/'} target="_blank" rel="noopener noreferrer"><img src={facebook} alt='facebook' /></a>
          <a href={'https://twitter.com/AdMachTec'} target="_blank" rel="noopener noreferrer"><img src={twitter} alt='twitter' /></a>
          <a href={'https://www.linkedin.com/company/admachtec'} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt='linkedin' /></a>
          <a href={'https://www.youtube.com/channel/UCfCvtiNM1kkv65uQs9gLusw'} target="_blank" rel="noopener noreferrer"><img src={youtube} alt='youtube' /></a>
        </div>

      </div>
      <div><small className='rights-mobile'>AdMachTec © 2024</small></div>
    </div>
  );
};

export default Footer;
