import React from "react";
import "./Gauge.css"; // Assuming you have the CSS in a separate file

const Gauge = ({value}) => {
  const newVal = value/5.7;
  const fillStyle = {
    transform: `rotate(${newVal / 2}turn)`
  };

  return (
    <div className="gauge">
      <div className="gauge__body">
        <div
          className="gauge__fill"
          style={fillStyle}
        ></div>
        <div className="gauge__cover">{`${Math.round(value * 100)}%`}</div>
      </div>
    </div>
  );
};

export default Gauge;
