import React, { useState } from "react";
import OurSoftwareImg from '../../images/Our-Software.webp';
import ContactForm from "../ContactForm/ContactForm";

export default function OurSoftware() {
  const [showContactForm, setShowContactForm] = useState(false);
  const product = "Custom Software"

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  }

  return (
    <div className="engineered-for-you">
      {showContactForm && <ContactForm productName={product} isshown={showContactForm.toString()} toggleContactForm={toggleContactForm} />}
      <div className="engineered-for-you-description">
        <h2>Our Software</h2>
        <p>In our pursuit of crafting top-of-the-line machines and equipment, our engineers and developers harness the most cutting-edge technology available. We will tailor our software to meet your specific needs, providing bespoke solutions that are exclusive to AdMachTec.
        </p>
        <div onClick={() => toggleContactForm()} className="engineered-for-you-btn">CONTACT US ABOUT CUSTOM SOFTWARE</div>
      </div>
      <div className="engineered-for-you-img">
        <img src={OurSoftwareImg} alt="Software" />
      </div>
    </div>
  )
}