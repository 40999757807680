import React from "react";
import Navbar from "../Navbar/Navbar";
import './Careers.css'

export default function Careers() {

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="careers-container">
          <div className="job-list">
            <h1>Careers</h1>
            <p>There are currently no open vacancies in our company.</p>
          </div>
          <div className="work-with-us">
            <h2>Work with us!</h2>
            <p>At AdMachTech, we're committed to developing high quality machines for the everyday builder. </p>
            <p>We can't achieve our goals alone and we are actively looking for qualified individuals that share our common passion for creativity and innovation. We'd love to hear your story! If you're located in Winnipeg, Manitoba feel free to apply to any of the positions we have available by sending your resume and a brief description of yourself and interests.</p>
          </div>
        </div>
      </div>
    </>
  )
}