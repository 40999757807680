import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Gauge from "./Gauge";
import SendBuildYourOwnForm from "./SendBuildYourOwnForm";
import nailer from "../../images/squaring-table.webp"
import './BuildYourOwn.css';

const nailerFeatures = [
  {
    id: 1,
    title: 'Independent Squaring Locks',
    description: 'Independent Squaring Locks: Allows multiple panels to be queued on the squaring table'
  },
  {
    id: 2,
    title: 'Cloud Integration',
    description: 'Cloud Integration: The squaring table can be integrated into our cloud software eco-system, offering enhanced reporting and diagnostics'
  },
  {
    id: 3,
    title: 'Production Reporting',
    description: 'Production Reporting: Provides feedback on all relevant aspects of wall panel production'
  },
  {
    id: 4,
    title: 'AMT Integration',
    description: 'AMT Integration: When installed with other AMT equipment, the squaring/sheathing table can be connected to the other AMT equipment in the line to provide further automation of your production'
  },
  {
    id: 5,
    title: 'Power Feed',
    description: 'Power Feed: This option not only lightens the workload but also prioritizes safety for your line personnel by utilizing a conveyor to move the panel. Additionally, it opens up the possibility for automation through seamless integration with AMT technology'
  },
  {
    id: 6,
    title: 'Multi Panel Support',
    description: 'Multi Panel Support: Allows panels to be queued on the squaring table for faster throughput. Most advantageous when producing short panels'
  },
  
  {
    id: 7,
    title: 'Raked Wall Support',
    description: 'Raked Wall Support: Support for squaring and clamping of raked walls'
  },
  
  
]

export default function BuildYourOwnSquaringTable() {
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [performanceBoost, setPerformanceBoost] = useState(100);
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState(['SELECTED FEATURES: ']);

  const product = "Squaring Table Basic";

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
    console.log('Features', selectedFeatures);
  }

  const handleAddFeature = (item) => {
    const isSelected = selectedFeatures.some((feature) => feature.id === item.id);
    if (!isSelected) {
      setSelectedFeatures([...selectedFeatures, item]);
      setPerformanceBoost(prevBoost => prevBoost + 10);
      setSelectedDescription([...selectedDescription, item.description]);
    } else {
      setSelectedFeatures(prevFeatures =>
        prevFeatures.filter((feature) => feature.id !== item.id)
      );
      setSelectedDescription((prevDescriptions) =>
        prevDescriptions.filter((description) => description !== item.description));
      setPerformanceBoost(prevBoost => prevBoost - 10);
    }
  };

  return (
    <>
      <Navbar />
      {showContactForm && <SendBuildYourOwnForm features={selectedFeatures} productName={product} isShown={showContactForm.toString()} toggleContactForm={toggleContactForm} />}
      <div className="container-wrapper static-background">
        <div className="build-your-own-wrapper">
          <div className="build-your-own-top-container">
            <div className="build-your-own-left-container">
              <div className="build-your-own-left-container-title">Squaring Table Basic</div>
              {/* <div className="build-your-own-left-container-numbers">
                {nailerFeatures.map((item, index) => (
                  <p key={index} style={{ backgroundColor: selectedFeatures.some((feature) => feature.id === item.id) ? "#3f72af" : "transparent" }}>{item.id}</p>
                ))}
              </div> */}
              <div className="build-your-own-left-container-add-feature">
                {
                  nailerFeatures.map((item, index) => (
                    <div key={index} className="build-your-own-left-container-add-feature-block">
                      <p>{item.title}</p>
                      <button
                        onClick={() => handleAddFeature(item)}
                        style={{ backgroundColor: selectedFeatures.some((feature) => feature.id === item.id) ? "#FAFAFA" : "transparent", color: selectedFeatures.some((feature) => feature.id === item.id) ? "#000000" : "#fafafa" }}
                      >
                        {selectedFeatures.some((feature) => feature.id === item.id) ? "Delete" : "Add"}
                      </button>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="build-your-own-right-container">
              <div className="build-your-own-right-container-perfomance">
                <div className="build-your-own-right-container-perfomance-img">
                  {/* <img src={Boost} alt="Boost" /> */}
                  <Gauge value={performanceBoost / 100} />
                </div>
                <div className="build-your-own-right-container-perfomance-count">
                  <p>Performance boost</p>
                  <p>{performanceBoost}%</p>
                  <button onClick={() => toggleContactForm(selectedFeatures)}>Send Inquire</button>
                </div>
              </div>
              <div className="build-your-own-right-container-description">
                {selectedDescription.map((description, index) => (
                  <p key={index}>{description}</p>
                ))}
              </div>
            </div>
          </div>

          <div className="build-your-own-bottom-container">
            <p className="build-your-own-bottom-container-description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Lorem ipsum dolor sit.</p>
            <div className="build-your-own-bottom-container-img"><img src={nailer} alt="Nailer" /></div>
          </div>
        </div>
      </div>
    </>
  )
}