import React from 'react';
import mainImage from '../../images/landing-main.webp';
import { HeroContainer, HeroBg, HeroButton, HeroBgImg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, Link } from './HeroElements';

function HeroSection() {
  return (
    <>
      <HeroContainer>
        <HeroBg>
          <HeroBgImg src={mainImage} alt='Main Image'>
          </HeroBgImg>
        </HeroBg>
        <HeroContent>
          <HeroH1>UNLEASH YOUR PRODUCTION POTENTIAL!</HeroH1>
          <HeroP>Experience the ultimate upgrade to your production line with our cutting-edge wall panel equipment and software Eco-system. Unleash unparalleled speed and uncompromising quality that will revolutionize your manufacturing process</HeroP>
          <HeroBtnWrapper>
            <Link to="/products/wall-panel-line">
              <HeroButton>
              Learn about our wall panel equipment line
              </HeroButton>
            </Link>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  )
}

export default HeroSection