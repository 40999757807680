import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { IoCloseSharp } from "react-icons/io5";

export default function SendBuildYourOwnForm({ isShown, toggleContactForm, productName, features, amountOfHeads }) {

  const [message, setMessage] = useState();
  const [company, setCompany] = useState("")
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [errorMessageClass, setErrorMessageClass] = useState(false);

  const selectedFeatures = features.map(i => i.title).join('. ');

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      product_name: productName,
      amount_of_nailer_heads: amountOfHeads,
      features: selectedFeatures,
      company_name: company,
      client_name: clientName,
      email: email,
      phone_number: phoneNumber,
      client_message: clientMessage
    }
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((response) => {
        console.log('Contact Form sent successfully!', response);
        setCompany('');
        setClientName('');
        setEmail('');
        setPhoneNumber('');
        setClientMessage('');
        setMessage('Contact Form sent successfully!');
        setErrorMessageClass(false);
      })
      .catch((error) => {
        console.error('Error sending Contact Form: ', error);
        setMessage('Something went wrong! Please try again!');
        setErrorMessageClass(true);
      });
  };

  return (
    <div isshown={isShown} className="contact-us-wrapper">
      <div className="contact-us-container">
        <IoCloseSharp className="close" onClick={toggleContactForm} />
        <div className="contact-us-container--heading">
          <h1>Contact Us</h1>
        </div>
        {message && <div className={`message-block ${errorMessageClass ? 'error-text' : ''}`}>
          {message}
        </div>}
        <form onSubmit={sendEmail}>
          {productName ? <p name="product_name">Product: <span>{productName}</span></p> : ''}
          <br />
          {amountOfHeads ? <p name="amount_of_nailer_heads">Amount of Nailer Heads: <span>{amountOfHeads}</span></p> : ''}
          <br />
          {features ? <p name="features">Selected Features: {selectedFeatures}</p> : ''}
          <div className="form-element">
            <label className="required">Company</label>
            <input
              required
              type="text"
              value={company}
              name="company_name"
              placeholder="Company Name"
              onChange={(e) => setCompany(e.target.value)} />
          </div>
          <div className="form-element">
            <label className="required">Name</label>
            <input
              required
              type="text"
              value={clientName}
              name="client_name"
              placeholder="Your Name"
              onChange={(e) => setClientName(e.target.value)} />
          </div>
          <div className="form-element">
            <label className="required">Email</label>
            <input
              required
              type="email"
              value={email}
              name="email"
              placeholder="Your Email"
              onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="form-element">
            <label>Phone</label>
            <input
              type="text"
              value={phoneNumber}
              name="phone_number"
              placeholder="Your Phone Number"
              onChange={(e) => setPhoneNumber(e.target.value)} />
          </div>
          <div className="form-element">
            <label className="required">Message</label>
            <textarea
              required
              type="text"
              value={clientMessage}
              name="client_message"
              placeholder="Your Message Here"
              onChange={(e) => setClientMessage(e.target.value)}></textarea>
          </div>
          <button>Submit</button>
        </form>
      </div>
    </div>
  )
}