import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./SubscribeForNews.css";

export default function SubscribeForNews() {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();
  const [errorMessageClass, setErrorMessageClass] = useState(false);

  const templateID = process.env.REACT_APP_NOTIFY;

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      email: email,
    }
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, templateID, templateParams, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((response) => {
        console.log('Contact Form sent successfully!', response);
        setEmail('');
        setMessage('Contact Form sent successfully!');
        setErrorMessageClass(false);
      })
      .catch((error) => {
        console.error('Error sending Contact Form: ', error);
        setMessage('Something went wrong! Please try again!');
        setErrorMessageClass(true);
      });
  };

  return (
    <div className="subscribe-container">
      <div className="subscribe-container-description">
        <h2>Elevate your production line with our cutting-edge wall panel equipment and software Eco-system.</h2>
        <p>Stay ahead of the curve with our newsletter, your go-to source for our latest product launches, updates, and industry insights. Stay in the know, effortlessly!</p>
        <div className="subscribe-wrapper--description_input">
          <form onSubmit={sendEmail}>
            <input
              required
              type="email"
              name="email"
              value={email}
              placeholder="Enter Your Email"
              onChange={(e) => setEmail(e.target.value)} />
            <button>Notify Me</button>
          </form>
        </div>
        {message && <div className={`message-block ${errorMessageClass ? 'error-text' : ''}`}>
          {message}
        </div>}
      </div>
    </div>
  )
}