import React from "react";
import Navbar from "../Navbar/Navbar";
import './SeparateProduct.css';
import SquaringTableImg from "../../images/squaring-table.webp";
import { HiMail } from "react-icons/hi";
import { ImPhone } from "react-icons/im";
import checkMark from "../../images/check-mark.png";
import { RiQuestionFill } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";

export default function SquaringTable() {

  return (
    <>
      <Navbar />
      <div className="container-wrapper static-background">
        <div className="product-container">
          <h1>Reserve your<br /> Squaring/Sheathing Table</h1>
          <p>The AdMachTec squaring table has been specifically engineered to maintain the squareness of wall panels during the attachment of sheathing and the cutting of rough openings, guaranteeing consistently high-quality panels.</p>
          <br />
          <p>Our squaring table models offer versatility, operating independently or seamlessly integrating with other AdMachTec wall panel equipment to automate your production line, ensuring accelerated output without compromising on consistent high quality.</p>
          <br />
          <p>Our features encompass adjustable end stops, panel-moving rollers for effortless maneuverability, user-friendly foot-switch or push-button controls, powered conveyors to streamline and automate your production line, along with multi-panel support to further enhance your production line capacity.</p>
        </div>

        <div className="separate-product-container">
          <div className="separate-product-container-image">
            <img src={SquaringTableImg} alt="nailer-XS" />
          </div>
          <div className="separate-product-container-content">
            <h3>Features</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill" /> Heavy duty welded steel construction</p>
              <p><GoDotFill size={10} className="dot-fill" /> Standard panel height from 4' to 12' and panel length of up to 20'</p>
              <p><GoDotFill size={10} className="dot-fill" /> Independent locking of panel</p>
              <p><GoDotFill size={10} className="dot-fill" /> Mechanical integration with AMT sheathing bridge</p>
              <p><GoDotFill size={10} className="dot-fill" /> Low profile tracks</p>
            </div>
            <h3>Options</h3>
            <div className="separate-product-content-features">
              <p><GoDotFill size={10} className="dot-fill" /> Panel sizes up to 16' high and 52' long</p>
              <p><GoDotFill size={10} className="dot-fill" /> Power Feed conveyor</p>
              <p><GoDotFill size={10} className="dot-fill" /> Integration with AMT Sheathing Bridge and upstream equipment</p>
              <p><GoDotFill size={10} className="dot-fill" /> Production Reporting</p>
              <p><GoDotFill size={10} className="dot-fill" /> Cloud Integration</p>
              <p><GoDotFill size={10} className="dot-fill" /> Multi Panel support</p>
              <p><GoDotFill size={10} className="dot-fill" /> Raked Wall support</p>
            </div>
            <h4>Pricing from: <span>$80000 <sup>(1)</sup> CAD</span></h4>
            <br />
            <p><sup>(1)</sup> Pricing depends on configuration</p>
            <div className="separate-product-container-btns">
              <a href='/contact'><button><HiMail className="info-icon" /> Contact Us</button></a>
              <a href='tel:+18003180389'><button><ImPhone className="info-icon" /> Call Us</button></a>
            </div>
          </div>
        </div>

        <div className="sheathing-bridge-features">
          <table className="comparison-table">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Foundation</th>
                <th>Advanced</th>
                <th>Ultimate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adjustable End Stops
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">The end stops can be placed in different positions based on the production line requirements</div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Mechanical Integration
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">Installed beneath an AMT Sheathing Bridge, the squaring table is mechanically connected to the bridge, guaranteeing precise and consistent alignment for optimal accuracy</div>
                </td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Independent Squaring Locks
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">Only the actual length of panel is locked in place. This allows multiple panels to be queued on the squaring table</div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Cloud Integration
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">The squaring table can be integrated into our cloud software eco-system, offering enhanced reporting and diagnostics</div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Production Reporting
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">Provides feedback on all relevant aspects of wall panel production</div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>AMT Integration
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">When installed with other AMT equipment, the squaring/sheathing table can be connected to the other AMT equipment in the line to provide further automation of your production</div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Power Feed
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">This option not only lightens the workload but also prioritizes safety for your line personnel by utilizing a conveyor to move the panel. Additionally, it opens up the possibility for automation through seamless integration with AMT technology</div>
                </td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Multi Panel Support
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">Allows panels to be queued on the squaring table for faster throughput. Most advantageous when producing short panels</div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
              <tr>
                <td>Raked Wall Support
                  <RiQuestionFill className="question dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" size={20} />
                  <div className="dropdown-menu dropdown-menu-dark">Support for squaring and clamping of raked walls</div>
                </td>
                <td></td>
                <td></td>
                <td><img src={checkMark} alt="check-mark" /></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="build-your-bridge">
          <p>For personalized configurations, please reach out to our representatives.</p>
          <a href="/contact"><button>Contact</button></a>
          {/* <a href="/products/build-squaring-table"><button>Build Your Own</button></a> */}
        </div>

      </div>
    </>
  )
}